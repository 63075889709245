import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTwitter, faDiscord, faTelegram } from '@fortawesome/free-brands-svg-icons';

function Social() {
    const iconStyle = {
      marginRight: '20px', // Agrega un espaciado de 20px entre cada icono
    };
    const containerStyle = {
      marginTop: '60px', // Agrega un margen superior de 30px
    };
  
    return (
      <div style={containerStyle}>
        <a href="https://twitter.com/nextdede" target="_blank" style={iconStyle}>
          <FontAwesomeIcon icon={faTwitter} style={{ color: 'DodgerBlue' }} />
        </a>
        <a href="https://discord.gg/rsjaXCUxQ8" target="_blank" style={iconStyle}>
          <FontAwesomeIcon icon={faDiscord} style={{ color: '#7289DA' }} />
        </a>
        <a href="https://t.me/+-ElWd2I7foVkYTY0" target="_blank" style={iconStyle}>
          <FontAwesomeIcon icon={faTelegram} style={{ color: '#0096cf' }} />
        </a>
      </div>
    );
  }
  
  export default Social;
