import './styles.css'; // Asegúrate de que esta importación esté al principio

import React from 'react';
import logo from './logo.png';
import './App.css';
import Social from './Social';
import TableWithButtons from './TableWithButtons'; // Ajusta la ruta según sea necesario

function App() {
  const spanStyle = {
    fontSize: '16px',
    color: '#ccc', // Puedes ajustar el color según tus preferencias
  };
  const buttonStyle = {
    boxShadow: 'rgb(5, 151, 0) 0px 0px 29px', // Ajusta los valores según tus preferencias
  };
  return (
    
    <div className="App">
      <header className="App-header">
        <img src={logo} className="pulse-animation" alt="logo" />
        <p>
          Listado de dominios activos
        </p>
        <span style={spanStyle}>Actualizado Marzo 2024</span>
        <a href="https://nextdede.us" target="_blank" rel="noopener noreferrer" className="btn btn-success btn-lg mt-3" style={buttonStyle}>
          nextdede.us
        </a>
        <TableWithButtons />
        {/* Agrega el componente MiComponente con los iconos y enlaces */}
        <Social />
      </header>
    </div>
  );
}

export default App;
