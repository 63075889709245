import React from 'react';

const TableWithButtons = () => {
  const data = [
    { id: 1, name: '', url1: 'https://nextdede.tv', url2: 'https://nextdede.com', url3: 'https://nextdede.top' },
    // Agrega más datos según sea necesario
  ];

  const cellStyle = {
    backgroundColor: 'transparent',
  };

  const tbodyStyle = {
    border: 'hidden', // Establece el borde del tbody a ninguno
  };
  const buttonStyle = {
    boxShadow: 'rgb(255, 0, 0) 0px 0px 29px', // Ajusta los valores según tus preferencias
  };

  return (
    <div className="container mt-4">
      <table className="table">
        <tbody style={tbodyStyle}>
          {data.map((item) => (
            <tr key={item.id} style={cellStyle}>
              <td style={cellStyle}>
                {item.name}
                <div className="mt-2">
                  <a href={item.url1} className="btn btn-danger btn-lg" target="_blank" rel="noopener noreferrer" style={buttonStyle}>
                    nextdede.tv
                  </a>
                </div>
              </td>
              <td style={cellStyle}>
                <div className="mt-2">
                  <a href={item.url2} className="btn btn-danger btn-lg" target="_blank" rel="noopener noreferrer" style={buttonStyle}>
                    nextdede.com
                  </a>
                </div>
              </td>
              <td style={cellStyle}>
                <div className="mt-2">
                  <a href={item.url3} className="btn btn-danger btn-lg" target="_blank" rel="noopener noreferrer" style={buttonStyle}>
                    nextdede.top
                  </a>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default TableWithButtons;
